import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import {
  addFont,
  addPart,
  addTrial,
  removeItem,
  saveCartTax,
  saveVoucherValid,
} from "../../actions/cartActions";

function Calc({ font, calcs }) {
  // Selectors
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const cartBillingInfo = useSelector((state) => state.cartBillingInfo);
  const { billingInfo } = cartBillingInfo;

  const cartVoucherValid = useSelector((state) => state.cartVoucherValid);
  const { voucherValid } = cartVoucherValid;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // State Etc
  const [parts, setParts] = useState([]);
  const [trials, setTrials] = useState([]);
  const [multi, setMulti] = useState(1);
  const [fontMultiPrice, setFontMultiPrice] = useState(
    parseInt(font.base_price)
  );
  const [calcId, setCalcId] = useState(0);
  const [calcDetails, setCalcDetails] = useState([]);
  const [calcName, setCalcName] = useState("Small license");
  const [unifiedDisplay, setUnifiedDisplay] = useState("d-block");
  const [trialDisplay, setTrialDisplay] = useState("d-none");
  const [fontDefault, setFontDefault] = useState([]);
  const [fontAdded, setFontAdded] = useState([]);
  const [vatRate, setVatRate] = useState(parseFloat(billingInfo.vatRate));
  const [voucherCode, setVoucherCode] = useState(voucherValid.code);

  // useEffects
  useEffect(() => {
    if (!vatRate) {
      setVatRate(0);
    }
  }, [vatRate]);

  // useEffects
  useEffect(() => {
    if (!voucherCode) {
      setVoucherCode("initial");
    }
  }, [voucherCode]);

  // Set Calc Active
  useEffect(() => {
    const updatedData = calcs.map((item, idx) => {
      if (idx === calcId) {
        return {
          ...item,
          select: true,
        };
      }
      return item;
    });
    setCalcDetails(updatedData);
  }, [calcs, calcId]);

  // Set Font Cart Status
  useEffect(() => {
    if (cartItems.find((ci) => ci.slug === font.slug)) {
      let updatedFontDefault = "d-none";
      let updatedFontAdded = "d-block";
      setFontDefault(updatedFontDefault);
      setFontAdded(updatedFontAdded);
    } else {
      let updatedFontDefault = "d-block";
      let updatedFontAdded = "d-none";
      setFontDefault(updatedFontDefault);
      setFontAdded(updatedFontAdded);
    }
  }, [font, cartItems]);

  // Set Part Cart Status and Price
  useEffect(() => {
    setParts(
      font.parts.map((item) => {
        if (cartItems.find((ci) => ci.slug === item.slug)) {
          return {
            ...item,
            part_default: "d-none",
            part_added: "d-block",
            multi_price: parseInt(item.base_price * multi),
          };
        } else
          return {
            ...item,
            part_default: "d-block",
            part_added: "d-none",
            multi_price: parseInt(item.base_price * multi),
          };
      })
    );
  }, [font, cartItems, multi]);

  // Set Trial Cart Status and Price
  useEffect(() => {
    setTrials(
      font.trials.map((item) => {
        if (cartItems.find((ci) => ci.slug === item.slug)) {
          return {
            ...item,
            trial_default: "d-none",
            trial_added: "d-block",
            multi_price: parseInt(item.base_price * 0),
          };
        } else
          return {
            ...item,
            trial_default: "d-block",
            trial_added: "d-none",
            multi_price: parseInt(item.base_price * 0),
          };
      })
    );
  }, [font, cartItems]);

  // Calc Select
  const onCalcSelect = (i, detail_calc_name, detail_multi) => {
    // Update Calc ID
    const updatedCalcId = i;
    // Update Multi
    const updatedMulti = detail_multi;
    // Update Font Price
    const updatedFontMultiPrice = parseInt(font.base_price * updatedMulti);
    // Update Calc Name
    const updatedCalcName = detail_calc_name;
    // Trial License Display
    if (detail_calc_name === "Trial license") {
      let updatedUnifiedDisplay = "d-none";
      let updatedTrialDisplay = "d-block";
      setUnifiedDisplay(updatedUnifiedDisplay);
      setTrialDisplay(updatedTrialDisplay);
    } else {
      let updatedUnifiedDisplay = "d-block";
      let updatedTrialDisplay = "d-none";
      setUnifiedDisplay(updatedUnifiedDisplay);
      setTrialDisplay(updatedTrialDisplay);
    }
    setCalcId(updatedCalcId);
    setMulti(updatedMulti);
    setFontMultiPrice(updatedFontMultiPrice);
    setCalcName(updatedCalcName);
  };

  // Add Font
  const onAddFont = (fontSlug, price, calcName) => {
    dispatch(addFont(fontSlug, price, calcName));
    dispatch(saveCartTax({ vatRate }));
    dispatch(saveVoucherValid(voucherCode));
  };

  // Add Part
  const onAddPart = (partSlug, price, calcName) => {
    dispatch(addPart(partSlug, price, calcName));
    dispatch(saveCartTax({ vatRate }));
    dispatch(saveVoucherValid(voucherCode));
  };

  // Add Trial
  const onAddTrial = (trialSlug, price, calcName) => {
    dispatch(addTrial(trialSlug, price, calcName));
    dispatch(saveCartTax({ vatRate }));
    dispatch(saveVoucherValid(voucherCode));
  };

  // Remove Item
  const onRemoveItem = (slug) => {
    dispatch(removeItem(slug));
  };

  return (
    <>
      {/* Calc Select */}
      <Container fluid className="fonts-license">
        <Row>
          <Col md={12}>
            <p className="subhead">Choose your license</p>
          </Col>
        </Row>
        <Row className="mt-4">
          {calcDetails.map((detail, i) => (
            <Col key={i} md={6}>
              {detail.type === "unified" && (
                <Card
                  className={
                    detail.select
                      ? "license-support license-active"
                      : "license-support license-inactive"
                  }
                  onClick={() => onCalcSelect(i, detail.name, detail.multi)}
                >
                  <p className="license-name">{detail.name}</p>
                  <p>
                    desktop: {detail.desktop}
                    <br />
                    web: {detail.web}
                    <br />
                    app/e-book: {detail.app}
                  </p>
                  <p>
                    <Link to={"/" + detail.link}>{detail.dest}</Link>
                  </p>
                </Card>
              )}
              {detail.type === "trial" && (
                <Card
                  className={
                    detail.select
                      ? "license-support license-active"
                      : "license-support license-inactive"
                  }
                  onClick={() => onCalcSelect(i, detail.name, detail.multi)}
                >
                  <p className="license-name">{detail.name}</p>
                  <p>{detail.desc}</p>
                  <p>
                    <Link to={"/" + detail.link}>{detail.dest}</Link>
                  </p>
                </Card>
              )}
              {detail.type === "contact" && (
                <Card>
                  <p className="license-name">{detail.name}</p>
                  <p>{detail.desc}</p>
                  <p>
                    <a href={"mailto:" + detail.link}>{detail.dest}</a>
                  </p>
                </Card>
              )}
            </Col>
          ))}
        </Row>
      </Container>
      {/* Unified Select */}
      <Container
        fluid
        className={"fonts-select unified-select " + unifiedDisplay}
      >
        {/* Font Default */}
        <div className={fontDefault}>
          <p className="subhead">
            Select the {font.name} fonts you would like to license
          </p>
          <Card
            className="select-inactive font-card"
            onClick={() => onAddFont(font.slug, fontMultiPrice, calcName)}
          >
            <Card.Body>
              <Row>
                <Col
                  lg={6}
                  className={"my-auto license-font-name " + font.font_class}
                >
                  {font.cart_name}
                </Col>
                <Col lg={2} className="my-auto">
                  {calcName}
                </Col>
                <Col lg={2} className="my-auto">
                  € {fontMultiPrice.toFixed(2)}
                </Col>
                <Col lg={2} className="my-auto">
                  <button type="button" className="btn btn-link">
                    Add to cart
                  </button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {font.type === "family" && (
            <>
              {parts
                .sort((a, b) => (a.part_id > b.part_id ? 1 : -1))
                .map((part, i) => (
                  <div key={i}>
                    {/* Part Default */}
                    <div className={part.part_default}>
                      <Card
                        className="part-card select-inactive"
                        onClick={() =>
                          onAddPart(part.slug, part.multi_price, calcName)
                        }
                      >
                        <Card.Body>
                          <Row>
                            <Col
                              lg={6}
                              className={
                                "my-auto license-font-name " + part.font_class
                              }
                            >
                              {part.cart_name}
                            </Col>
                            <Col lg={2} className="my-auto">
                              {calcName}
                            </Col>
                            <Col lg={2} className="my-auto">
                              € {part.multi_price.toFixed(2)}
                            </Col>
                            <Col lg={2} className="my-auto">
                              <button type="button" className="btn btn-link">
                                Add to cart
                              </button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                    {/* Part Added */}
                    <div className={part.part_added}>
                      <Card
                        className="part-card select-active"
                        onClick={() => onRemoveItem(part.slug)}
                      >
                        <Card.Body>
                          <Row>
                            <Col
                              lg={6}
                              className={
                                "my-auto license-font-name " + part.font_class
                              }
                            >
                              {part.cart_name}
                            </Col>
                            <Col lg={3} className="my-auto added">
                              {calcName}
                            </Col>
                            <Col lg={1} className="my-auto added">
                              € {part.multi_price.toFixed(2)}
                            </Col>
                            <Col lg={2} className="my-auto">
                              <button type="button" className="btn btn-link">
                                Added
                              </button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
        {/* Font Added */}
        <div className={fontAdded}>
          <p className="subhead">
            Select the {font.name} fonts you would like to license
          </p>
          <Card
            className="select-active font-card"
            onClick={() => onRemoveItem(font.slug)}
          >
            <Card.Body>
              <Row>
                <Col
                  lg={6}
                  className={"my-auto license-font-name " + font.font_class}
                >
                  {font.cart_name}
                </Col>
                <Col lg={3} className="my-auto added">
                  {calcName}
                </Col>
                <Col lg={1} className="my-auto added">
                  € {fontMultiPrice.toFixed(2)}
                </Col>
                <Col lg={2} className="my-auto">
                  <button type="button" className="btn btn-link">
                    Added
                  </button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {font.type === "family" && (
            <div className="select-disabled">
              {parts
                .sort((a, b) => (a.part_id > b.part_id ? 1 : -1))
                .map((part, i) => (
                  <Card key={i} className="part-card select-inactive">
                    <Card.Body>
                      <Row>
                        <Col
                          lg={6}
                          className={
                            "my-auto license-font-name " + part.font_class
                          }
                        >
                          {part.cart_name}
                        </Col>
                        <Col lg={3} className="my-auto added">
                          {calcName}
                        </Col>
                        <Col lg={1} className="my-auto added">
                          € {part.multi_price.toFixed(2)}
                        </Col>
                        <Col lg={2} className="my-auto">
                          <button type="button" className="btn btn-link">
                            Added
                          </button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
            </div>
          )}
        </div>
      </Container>
      {/* Trial Select */}
      <Container fluid className={"fonts-select trial-select " + trialDisplay}>
        {/* Trial Default */}
        <p className="subhead">
          Click “Add to cart” to license the {font.name} trial fonts
        </p>
        {trials
          .sort((a, b) => (a.trial_id > b.trial_id ? 1 : -1))
          .map((trial, i) => (
            <div key={i}>
              <div className={trial.trial_default}>
                <Card
                  className="font-card select-inactive"
                  onClick={() =>
                    onAddTrial(trial.slug, trial.multi_price, calcName)
                  }
                  key={i}
                >
                  <Card.Body>
                    <Row>
                      <Col
                        lg={6}
                        className={
                          "my-auto license-font-name " + trial.font_class
                        }
                      >
                        {trial.cart_name}
                      </Col>
                      <Col lg={2} className="my-auto">
                        {calcName}
                      </Col>
                      <Col lg={2} className="my-auto">
                        € {trial.multi_price.toFixed(2)}
                      </Col>
                      <Col lg={2} className="my-auto">
                        <button type="button" className="btn btn-link">
                          Add to cart
                        </button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
              <div className={trial.trial_added}>
                <Card
                  className="font-card select-active"
                  onClick={() => onRemoveItem(trial.slug)}
                  key={i}
                >
                  <Card.Body>
                    <Row>
                      <Col
                        lg={6}
                        className={
                          "my-auto license-font-name " + trial.font_class
                        }
                      >
                        {trial.cart_name}
                      </Col>
                      <Col lg={3} className="my-auto added">
                        {calcName}
                      </Col>
                      <Col lg={1} className="my-auto added">
                        € {trial.multi_price.toFixed(2)}
                      </Col>
                      <Col lg={2} className="my-auto">
                        <button type="button" className="btn btn-link">
                          Added
                        </button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </div>
          ))}
      </Container>
    </>
  );
}

export default Calc;
