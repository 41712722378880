import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listHomes } from "../../actions/homeActions";
import { Container, Offcanvas } from "react-bootstrap";

function List() {
  // Selectors
  const homeList = useSelector((state) => state.homeList);
  const { homes } = homeList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // State Etc
  // Off Canvas
  const [showTypeList, setShowTypeList] = useState(false);
  const handleCloseTypeList = () => setShowTypeList(false);
  const toggleShowTypeList = () => setShowTypeList((s) => !s);
  const backdropShowTypeList = false;
  const bodyScrollTypeList = true;

  // useEffects
  useEffect(() => {
    dispatch(listHomes());
  }, [dispatch]);

  return (
    <>
      <Container fluid className="list-contain">
        <div className="list-info text-center">
          <button
            type="button"
            className="btn btn-link"
            onClick={toggleShowTypeList}
          >
            Typeface list
          </button>
          <Offcanvas
            show={showTypeList}
            onHide={handleCloseTypeList}
            backdrop={backdropShowTypeList}
            scroll={bodyScrollTypeList}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>CakeType typefaces</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul className="nav-overflow-list">
                {homes
                  .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                  .map((home, i) => (
                    <li key={i} onClick={handleCloseTypeList}>
                      <a href={"/font/" + home.slug}>{home.name}</a>
                    </li>
                  ))}
              </ul>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </Container>
    </>
  );
}

export default List;
